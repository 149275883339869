import * as React from 'react'
import { graphql } from "gatsby"
import { useState, useEffect } from "react"
import axios from 'axios';
import Layout from '../components/header/header'
import RegHero from '../components/regHero/regHero'
import Footer from '../components/footer/footer'
import InsurerReview from '../components/insurerReview/insurerReview'
import GetStars from '../components/getStars/getStars'
import {Helmet} from "react-helmet";
import c from 'classnames'
import Modal from '../components/modal/modal'
import ReviewsSummary from '../components/reviewsSummary/reviewsSummary'

const WpInsurer = ({data}) => {
	const [ toggle, setToggle ] = useState([]);
	const [ total, setTotal ] = useState(0);
	const [ breeds, setBreeds ] = useState(false);
	const [ sort, setSort ] = useState(false);
	const [ stars, setStars ] = useState(false);
	const [ stored, setStored ] = useState(false);
	const [ searchQuery, setSearchQuery ] = useState(false);
	const [ percents, setPercents ] = useState([]);
	const [ remember, setRemember ] = useState(1);
	let ppp = 5;
	let numPage = false;
	const [ pagination, setPagination ] = useState();
	const [render, rerender] = useState(false);
	const [mynew, remynew] = useState(false);
	
	let dbid = data.wpInsurer.databaseId;
	let thisId = data.wpInsurer.databaseId;
	let insurers = data.wp.acfOptionsInsurer.insurerRatings;
	let all = data.allWpPage.edges;
	let excerpt = '';
	let rating = '';
	let uri = '';
	let currentTitle = data.wpInsurer.title;
	let currentUri = data.wpInsurer.uri;
	let ran = false;

	for (let index = 0; index < all.length; index++) {
		const element = all[index];
		let id = element.node.insurerReview.insurer.databaseId;
		if(thisId == id) {
			excerpt = element.node.insurerReview.excerpt;
			uri = element.node.uri;
			rating = element.node.insurerReview.rating.overallRating;
		}
	}

	useEffect(() => {
		getData(thisId);
		getStarsData(thisId);
	}, [ render, mynew ] );

	function postPagination(current, prev, next) {
		if (next) {
			let next = remember + 1;
			let betweenStart = ((next * ppp) - ppp) + 1;
			let betweenEnd = (next * ppp);
			setRemember(next);
			filterResults(stored, false, false, false, false, betweenStart, betweenEnd, false, next);
			sortPrevNext(next);
		} else if(prev) {
			let prev = remember - 1;
			let betweenStart = ((prev * ppp) - ppp) + 1;
			let betweenEnd = (prev * ppp);
			setRemember(prev);
			filterResults(stored, false, false, false, false, betweenStart, betweenEnd, false, prev);
			sortPrevNext(prev);
		} else {
			let betweenStart = ((current * ppp) - ppp) + 1;
			let betweenEnd = (current * ppp);
			setRemember(current);
			filterResults(stored, false, false, false, false, betweenStart, betweenEnd, false, current);
			sortPrevNext(current);
		}
	}


	function sortPrevNext(page) { 
		if (typeof window !== 'undefined') {
			let allpages = document.querySelectorAll('.loop-pagination--numbers a');

			allpages.forEach(function(item) {
				item.classList.remove('current');
			});
			
			// if(document.getElementById('page-'+ page)) {
			// 	document.getElementById('page-'+ page).classList.add('current');
			// }
		

			if(page == numPage) {
				document.querySelector('.next').classList.add('disabled');
			} else {
				document.querySelector('.next').classList.remove('disabled');
			}

			if(page == 1) {
				document.querySelector('.previous').classList.add('disabled');
			} else {
				document.querySelector('.previous').classList.remove('disabled');
			}
		}
	}

	function buildPagination(pageNum, current) {
		let pages = [];
		current = current - 1; 
		for (let i = 0; i < pageNum; i++) {
			if(i == 0) {
				pages.push(<a id={`page-${i+1}`} className={c({'current': current == 0})} key={`pagination-number${i + 1}`} href='javascript:void(0);' onClick={() => postPagination(i + 1, false, false)}>{i + 1}</a>);
				pages.push(<p>...</p>);
			}
			else if(i == current) {
				pages.push(<a id={`page-${i+1}`} className={c({'current': i == current})} key={`pagination-number${i + 1}`} href='javascript:void(0);' onClick={() => postPagination(i + 1, false, false)}>{i + 1}</a>);
				pages.push(<p>...</p>);
			}
			else if(i == (pageNum - 1)) {
				pages.push(<a id={`page-${i+1}`} key={`pagination-number${i + 1}`} href='javascript:void(0);' onClick={() => postPagination(i + 1, false, false)}>{i + 1}</a>);
			}
		}
		return pages;
	}

	function filterResults(data, incomingsort, incomingbreed, incomingstars, incomingsearch, start, end, redo, currentPage)  {
		if (typeof window !== 'undefined') {
			let myArray = [];

			if(render === false) {
				setStored(data);
				rerender(true);
			}

			//check if theres old filters already set
			if(!incomingsort && sort) {
				incomingsort = sort;
			}

			if(!incomingsearch && searchQuery) {
				incomingsearch = searchQuery;
			}

			if(!incomingbreed && breeds) {
				incomingbreed = breeds;
			}

			if(!incomingstars && stars) {
				incomingstars = stars;
			}

			//sort the data by rated or date
			if(incomingsort == 'rated') {
				data.sort(function(a, b) {
					return b.rating - a.rating;
				});
			} else {
				data.sort(function(a, b) {
					return new Date(b.date) - new Date(a.date);
				});
			}
			
			let filteredData = data;

			//remove items that dont match stars from original data
			if(incomingstars && incomingstars != 'all') {
				filteredData = filterStars(data, incomingstars);
			}

			//remove items that dont match breed from original data
			if(incomingbreed && incomingbreed != 'all') {
				filteredData = filterBreed(filteredData, incomingbreed);
			}

			//remove items that dont match search from original data
			if(incomingsearch && incomingsearch != 'all') {
				filteredData = filterSearch(filteredData, incomingsearch);
			}

			numPage = Math.ceil(filteredData.length / ppp);
			setPagination(buildPagination(numPage, currentPage));


			//loop through the filtered data and create the reviews
			filteredData.forEach(function(item, index) {
				let count = index + 1;
				if(count >= start && count <= end) {
					myArray.push(<InsurerReview data={item} />);
				}
			});

			//set reviews to be output
			setToggle(myArray);
		}
	}

	function filterStars(incoming, stars) {
		const filteredPosts = incoming.filter((item) => 
			item.rating == stars
		);
		return filteredPosts;
	}


	function filterBreed(incoming, breed) {
		const filteredPosts = incoming.filter((item) => 
			(item?.getBreed[0]?.name) ?
				item.getBreed[0].name == breed
			: false
		);
		return filteredPosts;
	}


	function filterSearch(incoming, searchTerm) {
		const filteredPosts = incoming.filter((item) => 
			item.title.toLowerCase().includes(searchTerm.toLowerCase()) || item.content.toLowerCase().includes(searchTerm.toLowerCase())
		);
		return filteredPosts;
	}

	const openModal = (elem) => {
		if (typeof window !== 'undefined') {
			let modalId = elem.target.dataset.modalid;
			let video = document.getElementById(modalId).querySelector('.embedIframe').dataset.src;
			document.getElementById(modalId).querySelector('.embedIframe').src = video;
			document.getElementById(modalId).classList.add('modalActive');
			document.getElementById(modalId).parentElement.classList.add('modalActive');
			document.querySelector('body').classList.add('hasModal');
		}
	}

	function filterDataByBreed(data) {
		if(data == '') {
			data = 'all';
		}
		setBreeds(data);
		postPagination(1, false, false);
		filterResults(stored, false, data, false, false, 1, 5, true, 1);
	}

	function filterDataBySearch(data) {
		if(data == '') {
			data = 'all';
		}
		setSearchQuery(data);
		postPagination(1, false, false);
		filterResults(stored, false, false, false, data, 1, 5, true, 1);
	}

	const formSubmit = (event) => {
		event.preventDefault();
	}


	function filterDataBySort(data) {
		if(data == '') {
			data = 'all';
		}
		setSort(data);
		postPagination(1, false, false);
		filterResults(stored, data, false, false, false, 1, 5, false, 1);
	}

	function filterDataByInsurer(data) {
		if(data != '') {
			if (typeof window !== 'undefined') {
				document.location.href = data;
			}
		}
	}

	function filterDataByStar(data) {
		if(data == '') {
			data = 'all';
		}
		setStars(data);
		postPagination(1, false, false);
		filterResults(stored, false, false, data, false, 1, 5, true, 1);
	}

	const getData = (id) => {
		axios.get('https://dev-petted2.pantheonsite.io/wp-json/site-reviews/v1/reviews?per_page=100&assigned_posts=' + id, {
			headers: {
				Authorization: "Basic cGV0dGVkYWRtaW46N0VnaW5pcE5aVlpqQml5TllOYzJoMmlS"
			}
		})
		.then(function (response) {
			filterResults(response.data, false, false, false, false, 1, 5, false, 1);
		})
		.catch(function (error) {
		})
		.finally(function () {
		});
	};

	const getStarsData = (id) => {
		axios.get('https://dev-petted2.pantheonsite.io/wp-json/site-reviews/v1/summary?assigned_posts=' + id, {
			headers: {
				Authorization: "Basic cGV0dGVkYWRtaW46N0VnaW5pcE5aVlpqQml5TllOYzJoMmlS"
			}
		})
		.then(function (response) {
			workoutPercents(response.data);
		})
		.catch(function (error) {
		})
		.finally(function () {
		});
	};

	function workoutPercents(data) {
		let ratings = data.ratings;
		let total = data.reviews;
		let myArray = [];
		setTotal(total);
		for (let index = 0; index < ratings.length; index++) {
			const element = ratings[index];
			myArray.push(Math.round((100 * element) / total) + '%');
		}
		setPercents(myArray);
	}

	setTimeout(() => {
		if (typeof window !== 'undefined') {
			let Choices = require('choices.js');
			let sortSelect = document.getElementById('sort');
			let starsSelect = document.getElementById('stars');
			let breedSelect = document.getElementById('breeds');
			let insurerSelect = document.getElementById('insurer');
			const sortChoices = new Choices(sortSelect, {
				allowHTML: false
			});
			const starsChoices = new Choices(starsSelect, {
				allowHTML: false
			});
			const breedChoices = new Choices(breedSelect, {
				allowHTML: false
			});
			const insurerChoices = new Choices(insurerSelect, {
				allowHTML: false
			});
		}
	}, 1000);

	return (
		<div>
			<Helmet>
				<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/choices.js/public/assets/styles/choices.min.css" />
				<script id="petted-quote-engine" src="https://petinsurer.azurewebsites.net/Scripts/lib/widgets/petted/vertical/quote-form/widget.min.js" type="text/javascript"></script>

				<script>{`
					window.addEventListener('load', function () {
					QuoteEnginePetted.setOptions({
						targetId: "petted-quote-form",
						redirectUrl: "https://petinsurer.azurewebsites.net/quote",
						baseUrl: "https://petinsurer.azurewebsites.net/",
						urlParam: { source: "PettedWidgetSingle", utm_source: "", utm_medium: "", utm_campaign: "", utm_content: "", utm_term: ""},
						refCode: "co",
					});
					QuoteEnginePetted.init();
				});
				`}</script>
			</Helmet>
			<Layout data={data.wpInsurer.seo}></Layout>
			<RegHero section={data.wpInsurer} title="" current={data.wpInsurer.uri} breadcrumbs={data.wpInsurer.seo.breadcrumbs} ></RegHero>
			<main className="main insurerSingle" role="main" id="main-content">
				<article>
					<div class="post__wrapper insurer__content container">
						<div class="col col-lg-2-3">
							<header>
								<img loading='lazy' src={data.wpInsurer.insurerDetails.logo.sourceUrl} alt="Logo" class="insurer__logo" />
								<a href="#reviews" class="unstyled review-summary">
									<ReviewsSummary data={insurers} id={data.wpInsurer.databaseId} />
								</a>
							</header>

							<div dangerouslySetInnerHTML={{__html: data.wpInsurer.insurerDetails.about}} />

							{
								(data?.wpInsurer?.insurerDetails?.fact) ? 
									<div class="fact">
										FACT! {data.wpInsurer.insurerDetails.fact}
									</div>
								: ''
							}

							<div class="insurer__details">
								{
									(data?.wpInsurer?.insurerDetails?.foundedIn) ? 
										<div>
											<h4>Founded in</h4>
											{data.wpInsurer.insurerDetails.foundedIn}
										</div>
									: ''
								}
								{
									(data?.wpInsurer?.insurerDetails?.numEmployees) ? 
										<div>
											<h4>Employees</h4>
											{data.wpInsurer.insurerDetails.numEmployees}
										</div>
									: ''
								}
								{
									(data?.wpInsurer?.insurerDetails?.headquarters) ? 
										<div>
											<h4>Headquarters</h4>
											{data.wpInsurer.insurerDetails.headquarters}
										</div>
									: ''
								}
							</div>
							{
								(data?.wpInsurer?.insurerDetails?.insurerVideos) ? 
									<div class="insurer__videos">
										{
											data.wpInsurer.insurerDetails.insurerVideos.map((video, index) => {
												return (
													<div>
														<div class="insurer__video-wrap">
															<h3>{video.videoTitle}</h3>
															<div onClick={openModal} class="embed-container content-video" data-modalid={`insurervid` + index} data-title={video.videoTitle} data-id={video.videoLink} 
															style={{ backgroundImage: `url('${video.placeholder.sourceUrl}')`}}></div>
														</div>

														<Modal data={`insurervid` + index} video={video.videoLink}></Modal>
													</div>
												)
											})
										}
									</div>
								: ''
							}
							<div class="border">
								<h3>Petted Review</h3> 
								{excerpt}
								<div class="insurer-rating-overview">
									<div class="rating-item">
										<div class="rating__value">
											<span class="rating__number">
												{rating}
											</span>
											<div class="rating__stars">
												<GetStars section={rating} />										
											</div>
										</div>
									</div>
								</div>
								<a href={uri} class="btn btn--slim">Read our full review</a>
							</div>
						</div>
						<div class="col col-lg-1-3">
							<div class="position-sticky">
								<div id="petted-quote-form"></div>
							</div>
						</div>
					</div>
					
					<div class="section">
						<div class="post__wrapper container">
							<div class="col col-1-1">
								<h2>User Reviews</h2>
							</div>
							<div class="col col-md-1-2 col-lg-1-3">
								<aside>
									<div class="review-summary">
										<div class="rating-item">
											<div class="rating__value">
												<span class="rating__number">
													{rating}
												</span>
												<div class="rating__stars">
													<GetStars section={rating} />										
												</div>
												<div class="rating__amount">
													<p>{total} user reviews</p>
												</div>
											</div>
										</div>
										<div class="review-summary__percentages">
											<a href="javascript:void(0);" onClick={() => filterDataByStar(5)} class="review-summary__percentage">
												<div class="review-summary__percentage-stars">
													<p>5 Star</p>
												</div>
												<div class="review-summary__percentage-bar">
													<div class="review-summary__percentage-bar-inner" style={{width: percents[5]}}></div>
												</div>
												<div class="review-summary__percentage-value">
													<p>{percents[5]}</p>
												</div>
											</a>
											<a href="javascript:void(0);" onClick={() => filterDataByStar(4)} class="review-summary__percentage">
												<div class="review-summary__percentage-stars">
													<p>4 Star</p>
												</div>
												<div class="review-summary__percentage-bar">
													<div class="review-summary__percentage-bar-inner" style={{width: percents[4]}}></div>
												</div>
												<div class="review-summary__percentage-value">
													<p>{percents[4]}</p>
												</div>
											</a>
											<a href="javascript:void(0);" onClick={() => filterDataByStar(3)} class="review-summary__percentage">
												<div class="review-summary__percentage-stars">
													<p>3 Star</p>
												</div>
												<div class="review-summary__percentage-bar">
													<div class="review-summary__percentage-bar-inner" style={{width: percents[3]}}></div>
												</div>
												<div class="review-summary__percentage-value">
													<p>{percents[3]}</p>
												</div>
											</a>
											<a href="javascript:void(0);" onClick={() => filterDataByStar(2)} class="review-summary__percentage">
												<div class="review-summary__percentage-stars">
													<p>2 Star</p>
												</div>
												<div class="review-summary__percentage-bar">
													<div class="review-summary__percentage-bar-inner" style={{width: percents[2]}}></div>
												</div>
												<div class="review-summary__percentage-value">
													<p>{percents[2]}</p>
												</div>
											</a>
											<a href="javascript:void(0);" onClick={() => filterDataByStar(1)} class="review-summary__percentage">
												<div class="review-summary__percentage-stars">
													<p>1 Star</p>
												</div>
												<div class="review-summary__percentage-bar">
													<div class="review-summary__percentage-bar-inner" style={{width: percents[1]}}></div>
												</div>
												<div class="review-summary__percentage-value">
													<p>{percents[1]}</p>
												</div>
											</a>
										</div>
									</div>

									<div class="filter-wrap">
										<div class="filterSearch">
											<form action="return false;">
												<div className="filterInput">
													<input type="text" placeholder="Search reviews..." onChange={e => filterDataBySearch(e.target.value, true)}></input>
												</div>
												<div className="filterSubmit">
													<button type="submit" onClick={formSubmit}></button>
												</div>
											</form>
										</div>
										<div className="filterSort">
											<p className="filterTitle">Sort by</p>
											<div className="selectWrap">
												<select name="sort" id="sort" onChange={e => filterDataBySort(e.target.value, true)}>
													<option value="">Most Recent</option>
													<option value="rated">Top Rated</option>
												</select>
											</div>
										</div>
										<div className="filterBy">
											<p className="filterTitle">Filter by</p>
											<div className="selectWrap" id="selectStars">
												<select name="stars" id="stars"  onChange={e => filterDataByStar(e.target.value, true)}>
													<option value="">All Stars</option>
													<option value="5">5 Star</option>
													<option value="4">4 Star</option>
													<option value="3">3 Star</option>
													<option value="2">2 Star</option>
													<option value="1">1 Star</option>
												</select>
											</div>
											<div className="selectWrap">
												<select name="breeds" id="breeds" onChange={e => filterDataByBreed(e.target.value, true)}>
													<option value="">All Breeds</option>
													{
														(data.allWpReviewCat.edges).map((breed, index) => {
															return (
																<option value={breed.node.name}>{breed.node.name}</option>
															)
														})
													}
												</select>
											</div>
										</div>
										<div className="filterJump">
											<p className="filterTitle">Jump to</p>
											<div className="selectWrap">
												<select name="insurer" id="insurer" onChange={e => filterDataByInsurer(e.target.value, true)}>
													<option value="">Insurer</option>
													{
														insurers.insurerRatings.map((insurer, index) => {
															return (
																<option value={insurer.insurer.uri}>{insurer.insurer.title} ({insurer.rating})</option>
															)
														})
													}
												</select>
											</div>
										</div>
									</div>
								</aside>
							</div>

							<div class="reviews-main col col-md-1-2 col-lg-2-3">
								<div class="reviews-wrap">
									{toggle}
								</div>
								<div class="reviews-numbers">
									<div className="loop-pagination">
										<a className="loop-pagination--button previous disabled" href="javascript:void(0);" onClick={() => postPagination(0, true, false)} rel="prev">
											&lt;
										</a>
										<div className="loop-pagination--numbers">
											{pagination}
										</div>
										<a className="loop-pagination--button next" href="javascript:void(0);" onClick={() => postPagination(0, false, true)} rel="next">
											&gt;
										</a>
									</div>
								</div>
								{/* <?php $form_id = get_field('review_form_id', 'option'); ?>
								<?php echo do_shortcode("[site_reviews assigned_posts='".$user_id."' form='".$form_id."' id='filtered-reviews' pagination=ajax]"); ?> */}
							</div>
							
						</div>
					</div>

				</article>
			</main>
			<Footer data={data.wp.acfOptionsFooter.footerOptions}></Footer>
		</div>
	)
}

export const query = graphql`
	query ($id: String) {
		wpInsurer(id: { eq: $id }) {
			title
			uri
			databaseId
			insurerDetails {
				logo {
				  sourceUrl
				}
				about
				fact
				foundedIn
				headquarters
				numEmployees
				insurerVideos {
					videoTitle
					videoLink
					placeholder {
						sourceUrl
					}
				}
			}
			seo {
				breadcrumbs {
					text
					url
				}
				fullHead
				schema {
					raw
				}
			}
			featuredHero {
				enableFeaturedHero
				fieldGroupName
				heroVideoId
				introduction
				pageTitle
				showBreadcrumbs
				heroImage {
					sourceUrl
					altText
				}
				heroImageMobile {
					sourceUrl
				}
			}
			hero {
				heroContent
				heroButtonText
				heroButtonLink {
					  url
				}
				heroImage {
					  sourceUrl
					  altText
				}
				heroMobileImage {
					  sourceUrl
				}
			}
			noneHomeHeader {
				nhhMoveImageDown
				nhhBackgroundImage {
					sourceUrl
				}
				nhhBackgroundImageMobile {
					sourceUrl
				}
				nhhImage {
					sourceUrl
					altText
				}
			}
			pageSettings {
				altHeaderWave
				noContainerOnMain
			}
		}
		allWpPage(filter: {template: {templateName: {eq: "Insurer Review"}}}) {
			edges {
				node {
					uri
					insurerReview {
						excerpt
						rating {
							claimsProcedure
							customerService
							easyToUnderstand
							overallRating
							valueForMoney
							speedOfClaims
						}
						insurer {
							... on WpInsurer {
								title
								databaseId
								uri
							}
						}
					}
				}
			}
		}
		allWpReviewCat {
			edges {
			  	node {
					uri
					name
				}
			}
		}
		wp {
			acfOptionsFooter {
				footerOptions {
					copyrightMessage
					facebook
					fieldGroupName
					footerLogo {
						sourceUrl
					}
					instagram
					tagline
					tiktok
				}
			}
			acfOptionsInsurer {
				insurerRatings {
					insurerRatings {
						rating
						insurer {
							... on WpInsurer {
								databaseId
								uri
								title
							}
						}
					}
				}
			}
		}
		allWpMediaItem {
			edges {
				node {
					id
					sourceUrl
					databaseId
				}
			}
	  	}
	}
`

export default WpInsurer