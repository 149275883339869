import * as React from 'react'
import * as Styles from './modal.module.scss'
import { useState } from "react"
import axios from 'axios';

const Layout = ({ data, video }) => {
	let id = data;
	const [ schema, setSchema ] = useState( '' );
	const closeModal = (elem) => {
		if (typeof window !== 'undefined') {
			let eles = document.querySelectorAll(".modalActive");
			document.querySelector('body').classList.remove('hasModal');
			eles.forEach(ele => {
				ele.classList.remove('modalActive');
				ele.querySelector('.embedIframe').src = '';
			});
		}
	}
	
	function getData(id, durationComing) {
		axios.get('https://www.googleapis.com/youtube/v3/videos?key=AIzaSyCOpewomC3Vzw3BxFDZR2MroBuGQsVntH4&part=snippet&id=' + id)
		.then(function (response) {
			let title = response.data.items[0].snippet.title;
			title = title.replace(/"/g, "'");
			title = title.replace(/\r?\n|\r/g, "");
			let desc = response.data.items[0].snippet.description;
			desc = desc.replace(/"/g, "'");
			desc = desc.replace(/\r?\n|\r/g, "");
			let uploadDate = response.data.items[0].snippet.publishedAt;
			let duration = durationComing;
			let video_id = id;
			let video_placeholder = 'https://img.youtube.com/vi/ ' + id + '/0.jpg';
			setSchema('<script type="application/ld+json">{"@context": "http://schema.org","@type": "VideoObject","name": "' + title + '", "description":"' + desc + '","thumbnailUrl":"' + video_placeholder + '","uploadDate":"' + uploadDate + '","duration":"' + duration + '","embedUrl":"https://www.youtube.com/embed/' + video_id + '"}</script>');
		})
		.catch(function (error) {
		})
		.finally(function () {
		});
	}

	function getDuration(id) {
		axios.get('https://www.googleapis.com/youtube/v3/videos?id=' + id + '&part=contentDetails&key=AIzaSyCOpewomC3Vzw3BxFDZR2MroBuGQsVntH4')
		.then(function (response) {
			getData(id, response.data.items[0].contentDetails.duration);
		})
		.catch(function (error) {
		})
		.finally(function () {
		});
	}

	if(video) {
		getDuration(video);
	}
	

	return (
		<div className={Styles.modalOverlay} onClick={closeModal}>
			<div
			role="dialog"
			id={id}
			aria-labelledby={id + "_title"}
			aria-modal="true"
			className={Styles.modal}
			data-open-page-load="false"
			>
				<div className={Styles.modalHeader}>
					<button className={Styles.modalClose} aria-label="Close Modal" onClick={closeModal}>
					<svg fill="none" stroke-width="2" stroke="currentColor" viewBox="0 0 24 24"><path d="M6 18L18 6M6 6l12 12"></path></svg>
					</button>
					<h2 className={Styles.modalTitle}></h2>
				</div>
				<div className={Styles.modalContent}>
					<div className="embed-container">
						<iframe data-src={'https://www.youtube.com/embed/' + video +'?enablejsapi=1'} src=''
							className='embedIframe'
							frameborder="0"
							allow="accelerometer; autoplay; encrypted-media; gyroscope;"
							allowfullscreen
							id="insurer-video"></iframe>
					</div>
				</div>
			</div>
			{
				schema ? <div dangerouslySetInnerHTML={{ __html: schema }} /> : ''
			}
		</div>
	)
}

export default Layout